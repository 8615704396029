const workshops = [
  {
    name: 'گریز از زمین',
    image: '/goriz-logo.png',
    description: [
      'به‌نظرتون چطور می‌شه با یه فرفره و یه سطل آب، از زمین فرار کرد؟',
      'تقریباً همه قبول دارن که فیزیک‌دان‌ها سعی کردن برای هرچیزی که تو طبیعت و دنیا هست، یک‌سری قاعده و قانون پیدا کنن.',
      'برای اون‌ها هیچ فرقی نداره چیزی که در حال توصیفش هستن، یه فرفره‌ی کوچیک باشه یا یه سفینه‌ی فضایی یا کره‌ی زمین؛ یه قانون وقتی ایده‌آل و زیباست که برای همه‌چیز (یا حداقل تمام چیزهای شبیه به هم!) قابل‌استفاده باشه.',
      'پس اگر دوست دارید بدونید چطور فرفره و سطل آب به فرارکردن از زمین ربط پیدا می‌کنن، کارگاه گریز از زمین منتظر شماست!',
      'راستی! تیزر کارگاهمون رو دیدید؟'
    ],
    teaserLink:'https://www.aparat.com/video/video/embed/videohash/haeZC/vt/frame'    
  },
  {
    name: 'صفر و یک',
    image: '/0,1logo.png',
    description: [
      'تا حالا به کامپیوترها دقت کردین؟ اصلاً بیایید همین‌الان به موبایل یا لپ‌تاپی که دارید باهاش این متن رو می‌خونید یه نگاه عمیق بندازید. این کامپیوترها می‌تونن کارهای خیلی جالب و خفنی انجام بدن و حضورشون توی زندگی‌هامون خیلی پررنگ شده؛ در حدی که حتی ارتباطتون با رستا (مسئله‌ای بسیار حیاتی!) هم از طریق اون انجام می‌شه! به‌نظرتون این وسایل از چه اجزای کوچیکی ساخته شدن؟ و یا این اجزای کوچیک با چه ترتیب و قواعدی کنار هم قرار می‌گیرن که می‌تونن چنین کارهایی انجام بدن؟',
      'توی این کارگاه اول قراره درباره‌ی منطق صحبت کنیم و ببینیم چطور منطق توی مسائل پیش‌پاافتاده‌ی روزمره هم حضور داره و حتی کمکمون هم می‌کنه. بعد به سراغ این می‌ریم که منطق چطور کمک می‌کنه دستگاه‌ها و مدارهای الکترونیکی‌ای بسازیم که بعضی کارها رو راحت‌تر واسه‌مون انجام بدن و آخر از همه هم برمی‌گردیم به سوال اولمون! مگه توی دل کامپیوترها چه اجزای کوچیکی وجود داره؟',
      'راستی! تیزر کارگاهمون رو دیدید؟'
    ],
    teaserLink:'https://www.aparat.com/video/video/embed/videohash/qBnuc/vt/frame'    
  },
  {
    name: 'الفبای حیاتی',
    image: '/bio-logo.png',
    description: [
      'حتماً تابه‌حال به خودتون و تفاوت‌هایی که با دیگران دارید، فکر کردید. به اینکه چرا رنگ چشمتون، مدل موهاتون، بلندی قدتون و اندازه‌ی انگشت‌هاتون نسبت به دوست‌هاتون یا حتی خواهر و برادرتون متفاوته؟ یا به اینکه بدن هرکدومتون چطور یه عامل خارجی رو تشخیص می‌ده و تصمیم می‌گیره باهاش مبارزه کنه یا نکنه؟',
      'تابه‌حال فکر کردید که اصلاً باعث و بانیِ این تفاوت‌ها کی یا چیه؟',
      'تازه، در عین همه‌ی تفاوت‌هایی که با هم داریم، باید بگیم که ما انسان‌ها ۹۹.۹٪ به همدیگه شبیهیم! اما از چه نظر؟ چه چیزی باعث می‌شه ما هم خیلی به هم شبیه و هم خیلی از هم متفاوت باشیم؟ خب، همون چیزی که ما توی کارگاهمون بهش می‌گیم زبان مشترک. همون DNA که احتمالاً قبل از این هم زیاد اسمش رو شنیدید. DNA هم مثل هر زبان دیگه، الفبا و قواعد خاص خودش رو داره. ما توی کارگاهمون می‌خوایم با این الفبای حیاتی و قواعدش آشنا بشیم و سعی کنیم حروف این الفبا رو کنار هم بذاریم، بخونیم و ببینیم چطوری این زبان، باعثِ به‌وجود اومدن ویژگی‌های موجودات زنده می‌شه.',
      'فکر می‌کنید کارگاه الفبای حیاتی یه کارگاه تماماً زیستیه؟ نه! ما توی این کارگاه برای حل مسئله‌هامون، قراره از الگوریتم‌ها کمک بگیریم تا بتونیم با این زبان بیشتر آشنا بشیم. می‌گید چطوری؟! خب کارگاه ما رو شرکت کنید تا در کنار هم بیشتر در این مورد یاد بگیریم. :))',
      'راستی! راجع به یه چیزی که این روزها نقل همه‌ی محافله (البته محافل مجازی) هم کلّی حرف داریم.',
      'پس منتظرتون هستیم!',
      'راستی! تیزر کارگاهمون رو دیدید؟'
    ],
    teaserLink:'https://www.aparat.com/video/video/embed/videohash/uBZrX/vt/frame'    
  },
  {
    name: 'صدا و فرکانس',
    image: '/seda-logo.png',
    description: [
      'وقتی می‌گیم صدای اون لامصب‌و کم کن دقیقا منظورمون اینه که چی‌شو کم کن؟؟؟!',
      'وقتی نور از منشور رد می‌شه چرا می‌شکنه و تجزیه می‌شه؟',
      'گوش ما چطور صداهای مختلف رو می‌شنوه؟!',
      'آیا می‌شه نویز و برفک صدا رو از روی اون حذف کرد؟!',
      'اصلا بین همین سوالای بالا چه ارتباطی هست؟ 😂',
      'در کارگاه صدا و فرکانس قصد داریم پس از معرفی یه سری مفاهیم اولیه مثل خود همین فرکانس(!)، به سراغ بررسی دقیق‌تر چیزهایی که در محیط اطراف‌مون می‌شنویم یا می‌بینیم بریم و باهاشون آشنا بشیم، از یه دیدگاه متفاوت و جالب‌تر به این پدیده‌ها نگاه کنیم.',
      'یه سری از پدیده‌های اطراف‌مون متناوبا تکرار می‌شن مثل روز و شب و ماه‌های سال، می‌خوایم ببینیم آیا بین سایر پدیده‌های اطراف‌مون با این پدیده‌های متناوب وجه مشترکی وجود داره؟',
      'با ما همراه باشید در کارگاه صدا و فرکانس=)',
      'راستی! تیزر کارگاهمون رو دیدید؟'
    ],
    teaserLink:'https://www.aparat.com/video/video/embed/videohash/gsNvw/vt/frame'
  },

  {
    name: 'جعل پیکسل',
    image: '/pixel-logo.png',
    description: [
      'تو دنیای امروز کمتر کسی پیدا می‌شه که با تصاویر سروکله نزده باشه؛ از عکسایی که هرلحظه با تلفن همراه‌مون می‌گیریم تا عکسایی که برای کاربردهای پزشکی و امنیتی و غیره گرفته می‌شه. اما اوضاع همیشه بر وفق مراد ما پیش نمی‌ره و خیلی وقت‌ها عکس‌هایی که می‌گیریم، اونجور که می‌خوایم نمی‌شن. علاوه بر این، ممکنه برای کارهای بعدیمون، فقط اطلاعات خاصی رو از اون عکس بخوایم که توی عکس خام، بین بقیه‌ی اطلاعات عکس، خیلی به چشم نمیان. توی این کارگاه قراره بفهمیم پسِ‌‌ پرده‌ی هر ویژگی‌ای که توی عکس می‌بینیم چه خبره و با دردست‌گرفتن این چرخ‌دنده‌های اصلی، عکسامون رو اونجوری که می‌خوایم اصلاح کنیم.',
      'راستی! تیزر کارگاهمون رو دیدید؟'
    ],
    teaserLink:'https://www.aparat.com/video/video/embed/videohash/QSakc/vt/frame'
  },
]

export default workshops;