const questions = [
  {
    text: 'رویداد برای کدوم دانش‌آموزاس؟',
    answerParagraphs: [
      'ورودی‌های دهم، یازدهم و دوازدهم می‌تونن توی رویداد شرکت کنن.'
    ],
  },

  {
    text: 'پایهٔ دانش‌آموزا بر اساس چه معیاری انتخاب شده؟',
    answerParagraphs: [
      'در جریان رویداد شما تعدادی سوال معماگونه حل خواهید کرد که برای حل اون‌ها، باید از برخی دانش‌های قبلی برخوردار باشید.'
    ],
  },

  {
    text: 'هزینه‌ی ثبت‌نام در رویداد چقدره؟',
    answerParagraphs: [
      'هزینه شرکت در رویداد برای هر فرد ۱۲۵ هزارتومانه اما در صورتی که به صورت گروهی در رویداد شرکت کنید هزینه‌ی رویداد برای هر فرد  ۱۰۰ هزارتومان خواهد بود اما دقت کنید که در مرحله‌ی اول ثبت‌نام نیاز نیست هزینه‌ای پرداخت کنید.'
    ],
  },

  {
    text: 'جزئیات رویداد رو از کجا بفهمیم؟',
    answerParagraphs: [
      'جزئیات رویداد روی کانال تلگرامی رویداد (@RastaihaOnline) اعلام می‌شه! و هم‌چنین از اینستاگرام ما هم غافل نباشید. (@Rastaiha)'
    ],
  },

  {
    text: 'رویداد فردیه یا گروهی؟',
    answerParagraphs: [
      'مدرسه تابستانه‌ی رستا به شکل گروهی برگزار میشه که هر گروه کارگاه‌های علمی و بازی رو با هم می‌گذرونند و به هم کمک می‌کنند.',
      'راستی اگر به شکل گروهی ثبت نام کنید، می‌تونید از تخفیف ثبت‌‌نام گروهی هم استفاده کنید.'
    ],
  },

  {
    text: 'گروه‌ها چند نفره‌اس؟',
    answerParagraphs: [
      'هر گروه 3 نفره‌اس ولی اگه کمتر از سه نفر هم هستید ما خودمون شما رو با دانش‌آموزای دیگه گروه می‌کنیم.'
    ],
  },

  {
    text: 'من که خونه نیستم چی؟',
    answerParagraphs: [
      'اولا که توصیه می‌کنیم برای جلوگیری از شیوع کرونا خونه بمونید؛ اما در این صورت هم کل رویداد ما بر بستر اینترنت هست و وبسایت رویداد هم با لپ‌تاپ و کامپیوتر و هم با گوشی قابل دسترسی‌ه!',
    ],
  },

  {
    text: 'برای ثبت‌نام توی رویداد باید چی‌کار کنیم؟',
    answerParagraphs: [
      'ما سه‌تا سوال ورودی داریم که باید حل کنین و جوابشو برامون بفرستین (نگران نباشید. سوال‌ها سخت نیستن!) بر اساس جوابی که به اون سوال‌ها می‌دید و یکسری فاکتور دیگه مثل زمان ثبت‌نام ما 180 نفر رو برای شرکت در مدرسه تابستانه رستا انتخاب می‌کنیم.',
      'البته هدف ما از سوال دادن بیشتر اینه که ببینیم کی اهل فکر کردن‌ه و جواب آخر خیلی هم برامون مهم نیست.',
    ],
  },

  {
    text: 'اعضای یه گروه می‌تونن از پایه‌های مختلف باشن؟',
    answerParagraphs: [
      'بله اعضای یک گروه می‌تونن از هرکدوم از پایه‌های دهم تا دوازدهم باشن و محدودیتی برای اینکه هم‌پایه باشن وجود نداره.'
    ],
  },

  {
    text: 'کلا رویداد چقدر قراره ازمون وقت بگیره؟',
    answerParagraphs: [
      'رویداد به شکلی برنامه‌ریزی شده که زمان مورد نیاز برای هر کارگاه در حدود ۴ الی ۵ ساعت باشه اما در هر روز برنامه‌های دیگه مثل شهربازی یا دورهمی هم داریم که البته شرکت در اونها اختیاریه و زمان‌هایی هم برای استراحت در نظر گرفتیم با احتساب همه‌ی این موارد هر روز از ۹ صبح الی ۱۹ برنامه‌های مربوط به رویداد رو داریم.'
    ],
  },
];

export default questions;
